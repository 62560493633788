import React from 'react';

export const Text = ({ text }) => {
  return (
    <div className="w-full">

      <div className="prose prose-lg max-w-full article-prose" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
