import React from 'react';
import Image from '~/components/elements/Image';

export const PostImage = (prop) => {
  const { image } = prop
  return (
    <figure className="rounded-2xl overflow-hidden">
      <Image image={image} objectFit="contain" className="max-w-full w-full h-auto" />
    </figure>
  )
}