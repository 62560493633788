import { Link } from 'gatsby';
import React from 'react';
import { WpAcfLink } from '~/_generated/types';

export enum ButtonType {
  Button = 'button',
  Text = 'text',
  Outline = 'button-outline',
}

export interface ButtonProps {
  link: WpAcfLink;
  type: ButtonType;
  color: string;
  removeArrow?: boolean;
  arrowFill?: boolean;
}

export const Button = ({ link, type, color, removeArrow = false, arrowFill = true }: ButtonProps) => {
  if (!link) return null;

  let typeClasses = '';
  if (type === 'button') {
    if (color === 'coral') {
      typeClasses = 'btn btn-coral';
    } else if (color === 'orange') {
      typeClasses = 'btn btn-orange';
    } else {
      typeClasses = 'btn btn-beige';
    }
  } else if (type === 'text') {
    typeClasses = 'text-link';
  } else {
    typeClasses = 'hover:opacity-50 transition-opacity';
  }

  return (
    <>
      {type === 'button' ? (
        <Link
          to={link?.url || '#'}
          // w-[fit-content] md:w-auto mx-auto
          className={`${typeClasses} flex items-center space-x-[9px] whitespace-nowrap justify-center group `}
        >
          <span>{link?.title}</span>
          {removeArrow === true ? null : (
            <span className="inline-block group-hover:translate-x-[6px] transition-all duration-200">
              <ButtonArrow color={color} />
            </span>
          )}
        </Link>
      ) : (
        <Link
          to={link?.url || '#'}
          className={`${typeClasses} flex items-center space-x-[9px] whitespace-nowrap justify-center group`}
        >
          <span>{link?.title}</span>
          {removeArrow === true ? null : (
            <span className="inline-block group-hover:translate-x-[6px] transition-all duration-200">
              <ButtonArrow arrowFill={arrowFill} />
            </span>
          )}
        </Link>
      )}
    </>
  );
};

export const ButtonArrow = ({ color, arrowFill }: any) => {
  return (
    <svg
      className={`w-full h-full max-w-[24px] max-h-[24px] button-arrow`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={arrowFill ? '#F66B4C' : 'currentColor'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2378 8.1591L16.8345 11.6159C17.0552 11.828 17.0552 12.172 16.8345 12.3841L13.2378 15.8409C13.0171 16.053 12.6592 16.053 12.4385 15.8409C12.2178 15.6288 12.2178 15.2849 12.4385 15.0727L15.0704 12.5432H6V11.4568H15.0704L12.4385 8.92728C12.2178 8.71515 12.2178 8.37122 12.4385 8.1591C12.6592 7.94697 13.0171 7.94697 13.2378 8.1591Z"
        fill={`${color === 'coral' ? 'black' : 'white'}`}
      />
    </svg>
  );
};
