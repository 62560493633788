import React from 'react';
import { Text } from '~/components/flexible/article/Text';
import { Quote } from '~/components/flexible/article/Quote';
import { PostImage } from '~/components/flexible/article/Image';
import { Bullet } from '~/components/flexible/article/Bullet';
import { Statistics } from '~/components/flexible/article/Statistics';

export const ArticleRenderer = ({ prefix, content = [] }) => {
  return (
    <div className="prose prose-lg !max-w-full space-y-10 md:space-y-14">
      {content?.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        switch (layoutName) {
          case 'Text' :
            return <Text key={layoutName+i} {...layout} />; break;
          case 'Quote' :
            return <Quote key={layoutName+i} {...layout} />; break;
          case 'Image':
            return <PostImage key={layoutName+i} {...layout} />; break;
          case 'Statistics':
            return <Statistics key={layoutName+i} {...layout}/>; break;
          case 'Bullet':
            return <Bullet key={layoutName+i} {...layout} />; break;
          default:
            console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </div>
  )
};