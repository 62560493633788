import React from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';

export default function Footer({ data, pageColour, hide }) {
  const footer = data?.optionsFooter;

  const footerClasses = [];

  if (pageColour === 'darkGreen') {
    footerClasses.push('bg-darkGreen !text-white pt-6 md:pt-12 lg:pt-20 xl:pt-[115px]');
  } else {
    footerClasses.push('bg-taupe !text-darkGreen');
  }

  if (hide) {
    footerClasses.push(' !hidden ');
  }

  return (
    <footer className={`${footerClasses}`}>
      <div className="container grid grid-cols-12  pt-16 pb-12 md:pt-24 md:pb-20 lg:pt-[135px] lg:pb-[110px]">
        {/* Column 1 */}
        <div className="col-span-12 md:col-span-4 flex flex-col order-last md:order-first mt-6 md:mt-0">
          <Image image={footer?.footerLogo} className={'max-w-[192px]'} />
          <h4 className="text-h4 max-w-[340px] !leading-[1.2] !font-medium">{footer.footerHeading}</h4>

          {/* Social Menu */}
          <div className="flex space-x-2 my-6 md:my-8">
            {footer.socialMenu.map((item, i) => (
              <div
                className="bg-orange w-[30px] h-[30px] rounded-md hover:!scale-[1.2] transition-all duration-300"
                key={i}
              >
                <a
                  className="flex flex-col item-center justify-center w-full h-full"
                  href={item.link.url}
                  target={item.link.target}
                >
                  <div className="w-[16px] h-[14px] mx-auto">
                    <Image image={item.icon} className={`w-full h-full object-contain object-center`} />
                  </div>
                </a>
              </div>
            ))}
          </div>

          {/* Accreditation and Copyright */}
          <div className="mt-auto md:-translate-y-5 md:mt-20">
            <div className="space-x-2 mb-6 md:mb-10">
              {footer.accreditationLogos.map((item, i) => (
                <div key={i} className="w-[50px] h-[50px]">
                  <Image image={item.logo} className={`w-full h-full object-contain object-center`} />
                </div>
              ))}
            </div>
            <p className="!text-sm">
              © {new Date().getFullYear()} {footer.copyrightMessage}
            </p>
          </div>
        </div>

        {/* Footer menus */}
        <div className="col-span-12 md:col-span-8 lg:pl-20 flex flex-wrap order-first md:order-last">
          {footer.footerMenu.map((column, i) => (
            <div key={i} className="mb-8 md:mb-16 lg:mb-[72px] w-1/2 md:w-1/4">
              <h4 className="font-body text-lg font-bold mb-5">{column.heading}</h4>
              <ul className="space-y-2">
                {column.menuItems.map((item, i) => (
                  <li key={i}>
                    <Link
                      to={item.link?.url || '#'}
                      target={item.link?.target}
                      className={`text-base hover:opacity-50 transition-all duration-300`}
                    >
                      <span>{item.link?.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
}
