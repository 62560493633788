import React from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
import Footer from '~/components/global/Footer';
import Header from '~/components/global/Header';
export const OptionsContext = React.createContext(null);

export default function Layout({ wp, page, children, pageColour, hide, styleVersion }: any) {
  const seo = useSiteInfoAssets();

  const mainClasses = [];

  if (pageColour === 'darkGreen') {
    mainClasses.push(' text-white bg-darkGreen ');
  } else if (pageColour === 'darkestGreen') {
    mainClasses.push(' bg-white ');
  }

  // unpurge — !mt-0
  // 2778 is the ID of the Speak With Us page
  return (
    <OptionsContext.Provider value={{ options: wp, page }}>
      <SEOContext.Provider value={{ global: seo }}>
        <Header data={wp?.header} pageColour={pageColour} hide={hide} />
        <main className={`${mainClasses} page-slug-${page?.slug} page-id-${page?.databaseId} styles-${styleVersion}`}>
          {children}
        </main>
        {page?.databaseId !== 2778 ? <Footer data={wp?.footer} pageColour={pageColour} hide={hide} /> : null}
      </SEOContext.Provider>
    </OptionsContext.Provider>
  );
}
