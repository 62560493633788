import React, { useState } from 'react';
import { MobileSubMenuPost } from './SubMenuPost';
import { MobileSubMenuImage } from './SubMenuImage';
import { AnimatePresence, motion } from 'framer-motion';
import { ButtonArrow } from '../elements/Icon';
import { SubnavItem } from '~/components/global/Header';
import { Link } from 'gatsby';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';

export const MobileMenu = ({ active, optionsHeader, menus }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const menuHandler = (i) => {
    setOpenSubmenu(true);
    setActiveMenu(i);
  };

  return (
    <div
      className={`z-[99] bg-darkGreen text-white fixed w-screen h-screen mt-[-62px] transition-all duration-200 overflow-scroll ${
        active ? '' : 'translate-y-[-100vh]'
      }`}
    >
      <div className="border-b border-white mt-[62px]" />
      <InViewAnimator
        inner={(controls) => (
          <>
            <div className="px-[10px] py-[18px] space-y-[10px] items-center text-[26px] font-normal flex flex-col ">
              {optionsHeader?.menu?.map((menuLink, i) => (
                <InViewAnimatorBasicItem controls={controls} delay={i / 8} duration={1} key={i} className="w-full">
                  <motion.div
                    key={`menuLink${i}`}
                    className="w-full rounded-[5px] bg-fadedGreen overflow-hidden"
                    onClick={() => {
                      menuLink?.showSubmenu && menuHandler(i === activeMenu ? null : i);
                    }}
                  >
                    <Link
                      className="relative w-full flex items-center space-x-6 justify-between z-10 px-5 py-[25px] transition-all duration-200 sm:hover:bg-white sm:hover:text-darkGreen overflow-hidden"
                      to={menuLink?.link?.url}
                    >
                      {menuLink?.link?.title}
                      {menuLink?.showSubmenu && (
                        <span
                          className={`transition-all duration-200 ${
                            openSubmenu && activeMenu === i ? '-rotate-90' : 'rotate-90'
                          }`}
                        >
                          <ButtonArrow />
                        </span>
                      )}
                    </Link>

                    <AnimatePresence>
                      {openSubmenu && activeMenu === i && (
                        <motion.div
                          key={`menuWrap`}
                          initial={{ y: 20, opacity: 0 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 20, transition: { duration: 0.2 } }}
                          transition={{ duration: 0.4, y: { duration: 0.4 } }}
                          className="w-full pb-6 px-2 "
                        >
                          {openSubmenu && activeMenu === i && (
                            <motion.div key={`menu${i}`} layoutId={`menu${i}`} transition={{ duration: 0.4 }} exit={{transition:{duration:.1}}} className={`space-y-4`}>
                              {menus[menuLink?.link?.title] ? menus[menuLink?.link?.title].items.map((item)=>(
                                <div  key={`mobile${item.heading}`}><SubnavItem {...item}/></div>
                              )):null}
                            </motion.div>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                </InViewAnimatorBasicItem>
              ))}
            </div>
            <div className="px-[10px] mt-[28px] relative z-10 text-center items-center space-y-[10px] flex flex-col">
              <InViewAnimatorBasicItem controls={controls} delay={0.4} duration={1} className="block w-full">
                <Link
                  to={'/contact'}
                  className="block btn btn-coral-simple w-full max-w-[315px] mx-auto !py-4 border border-coral"
                >
                  Speak with us
                </Link>
              </InViewAnimatorBasicItem>
            </div>
            <div className="px-[10px] my-8 text-center">
              <p className="!text-sm">© {new Date().getFullYear()} Setpoint</p>
            </div>
          </>
        )}
      />
    </div>
  );
};
