import React from 'react';

export const Statistics = (props) => {
  const { statisticItem } = props

  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-10">
      {
        statisticItem?.map((stat, i) => (
          <li key={i} className="bg-darkGreen rounded-2xl min-h-[160px] lg:min-h-[325px] p-4 lg:p-10">
            <div className="flex flex-col justify-end w-full h-full text-white">
              <div>
                <span className="block font-heading t-66">{ stat.number }</span>
                <span className="block">{ stat.description }</span>
              </div>
            </div>
          </li>
        ))
      }
    </ul>
  )
}