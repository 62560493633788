import React from 'react';

export const Bullet = (props) => {
  const { heading, list } = props
  return (
    <div className="bg-darkerGreen text-white rounded-2xl p-4 pt-8 lg:py-14 lg:px-20 relative overflow-hidden
     before:absolute before:inset-x-0 before:top-0 before:h-3 before:bg-coral">
      <h3 className="t-24 mb-9">{ heading }</h3>
      <ul className="pl-3 list-disc marker:text-coral">
        {
          list && list?.map((item, index) => (
            <li className="mb-4 t-18" key={index}>
              {item.listItem}
            </li>
          ))
        }
      </ul>
    </div>
  )
}