import React from 'react';
import Image from '~/components/elements/Image';

export const Quote = (props) => {
  const { logo, text, cite, position} = props
  return (
    <blockquote className="">
      {logo &&
        <Image className="max-w-[85px] mb-9" image={logo}/>
      }
      <span className="block not-italic text-white t-24 leading-[1.33] mb-9" dangerouslySetInnerHTML={{__html : text}}/>
      <cite className="not-italic font-body text-white">
        { (cite?cite:'') + (cite && position? ', ':'') + (position?position:'')}
      </cite>
    </blockquote>
  )
}