import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Logo } from '../elements/Icon';
import { Chevron } from '../elements/Icon';
// import { SubMenuImage } from './SubMenuImage';
// import { SubMenuPost } from './SubMenuPost';

// Mobile Menu
import { MobileMenu } from './MobileMenu';

const menus = {
  Solutions: {
    type: 'one-col',
    items: [
      {
        heading: 'Technology',
        content: 'Explore the operating system for borrowers and lenders.',
        url: '/platform/',
      },
      {
        heading: 'Capital',
        content: 'Bespoke debt facilities for growth.',
        url: '/capital/',
      },
    ],
  },
  Customers: {
    type: 'one-col',
    items: [
      {
        heading: 'Borrowers',
        content: 'Optimize capital use and automate operations.',
        url: '/borrowers/',
      },
      {
        heading: 'Lenders',
        content: 'Standardize borrower data and ensure compliance.',
        url: '/lenders/',
      },
    ],
  },

  Resources: {
    type: 'one-col',
    items: [
      {
        heading: 'Resources Hub',
        url: '/resources/',
      },
      {
        heading: 'Blog',
        url: '/resources#Blog',
      },
      {
        heading: 'Press',
        url: '/resources#Press-Releases',
      },
      // {
      //   heading: 'Customer Stories',
      //   url: '/resources#Customer-Stories',
      // },

    ],
  },
};

function SubmenuHandler({ menuLink }) {


  return menus[menuLink?.link?.title] ? (
    <>
      {menus[menuLink?.link?.title].type === 'one-col' ? (
        <SubmenuTwoCol menu={menus[menuLink?.link?.title].items} />
      ) : null}
    </>
  ) : null;
}

export function SubnavItem({ heading, content, url }) {
  return (
    <Link
      to={url}
      className="bg-white hover:bg-taupe transition-colors duration-200 ease-in-out rounded-[12px] overflow-hidden px-4 py-3 flex flex-col"
    >
      <div className={`flex space-x-3 items-center ${!content? 'justify-between':''}`}>
        <div className="t-16 text-darkGreen font-medium">{heading}</div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect width="20" height="20" rx="10" fill="#F66B4C" />
            <path d="M14 10H6M14 10L11 7M14 10L11 13" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      {content ? (
        <div className="t-14 text-dark70 opacity-90 mt-2 leading-[1.27] font-normal" style={{ textWrap: 'pretty' }} dangerouslySetInnerHTML={{ __html: content }} />
      ) : null}
    </Link>
  );
}

function SubmenuTwoCol({ menu }) {
  return (
    <div className={`grid gap-1 min-w-[280px]`}>
      {menu.map((item, i) => (
        <SubnavItem key={item.heading} {...item} />
      ))}
    </div>
  );
}

export default function Header({ data, pageColour, hide }) {
  const { optionsHeader } = data;

  const [showHeaderBg, setShowHeaderBg] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const menuHandler = (i) => {
    setOpenSubmenu(true);
    setActiveMenu(i);
  };

  const reset = () => {
    setOpenSubmenu(false);
    setActiveMenu(null);
  };

  const [toggleActive, toggleIsActive] = useState(false);
  const handleToggle = () => {
    toggleIsActive(!toggleActive);
  };

  const headerClasses = [];
  const headerBg = [];
  const menuControls = [];

  if (pageColour === 'darkGreen') {
    headerClasses.push(' text-white ');
    headerBg.push(' bg-darkGreen ');
    menuControls.push('bg-taupe');
  } else if (pageColour === 'darkestGreen') {
    headerClasses.push(' text-white ');
    headerBg.push(' bg-darkestGreen ');
    menuControls.push('bg-taupe');
  } else {
    menuControls.push('bg-darkGreen');
  }

  if (hide) {
    headerBg.push(' !hidden ');
  }

  useEffect(() => {
    const scrollEvent = (e) => {
      if (window.scrollY > 80) {
        setShowHeaderBg(true);
      } else {
        setShowHeaderBg(false);
      }
    };

    window.addEventListener('scroll', scrollEvent);

    return () => window.removeEventListener('scroll', scrollEvent);
  }, []);

  return (
    <header
      className={`${
        toggleActive ? 'fixed' : `absolute`
      } md:absolute top-0 left-0 right-0 z-[100] transition-colors duration-300 ${headerBg} ${
        showHeaderBg ? `${headerBg?.length ? 'headerBg' : '!bg-taupe'}` : ''
      } `}
      role="banner"
    >
      <div className="relative z-[9999] px-[25px] w-full justify-between mx-auto py-5 md-largepy-[17px] flex items-center">
        <div className="flex items-center space-x-6 lg:space-x-[60px]">
          {pageColour === 'darkGreen' || pageColour === 'darkestGreen' ? (
            <Link className="relative z-[9999] max-w-[100px] lg:min-w-[120px]" to={'/'}>
              <Logo mobileActive={true} />
            </Link>
          ) : (
            <Link className="relative z-[9999] max-w-[100px] lg:min-w-[120px]" to={'/'}>
              <Logo mobileActive={toggleActive} />
            </Link>
          )}
          <div className=" items-center  text-[15px] font-medium hidden md-large:flex ">
            {optionsHeader?.menu?.map((menuLink, i) => (
              <motion.div
                key={`menuLink${i}`}
                className={`xl:relative px-[17px] flex items-center space-x-2 ${
                  menuLink?.submenu?.type === 'with-image' ? '' : 'relative'
                }`}
                onHoverStart={() => {
                  menuLink?.showSubmenu && menuHandler(i);
                }}
                onHoverEnd={reset}
              >
                <Link className={`relative z-10 ${headerClasses}`} to={menuLink?.link?.url}>
                  {menuLink?.link?.title}
                </Link>
                {menuLink?.showSubmenu && (
                  <Chevron color={pageColour === 'darkGreen' || pageColour === 'darkestGreen' ? '#fff' : '#164951'} />
                )}
                <AnimatePresence>
                  {openSubmenu && activeMenu === i && (
                    <motion.div
                      key={`menuWrap${i}`}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
                      transition={{ duration: 0.2, y: { duration: 0.2 } }}
                      className={`absolute left-0 right-auto min-w-fit top-0 xl:pt-12 flex  pt-12`}
                    >
                      <div key={`menu${i}`} className={`rounded-[20px] bg-white overflow-hidden p-2`} style={{boxShadow:'0px 4px 20px 0px rgba(0, 0, 0, 0.05)'}}>
                        <SubmenuHandler menuLink={menuLink} />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="relative z-10 items-center space-x-[9px] hidden  md-large:flex">
          <Link to={'/speak-with-us/'} className="btn btn-orange !px-[12px] !py-[8px]">
            Speak with us
          </Link>
        </div>

        <div className="md-large:hidden">
          <MobileMenuToggle toggle={handleToggle} active={toggleActive} menuControls={menuControls} />
        </div>
      </div>
      <div className="md-large:hidden">
        <MobileMenu active={toggleActive} optionsHeader={optionsHeader} menus={menus} />
      </div>
    </header>
  );
}

const MobileMenuToggle = ({ toggle, active, menuControls }) => {
  const menuBar = ['absolute w-full transition-all duration-200'];
  return (
    <div className="relative z-[9999]">
      <div onClick={toggle} className={`relative cursor-pointer top-0 w-[16px] h-[12px]`}>
        <span
          className={`h-[1.7px] ${menuBar} ${active ? 'top-[48%] rotate-45 bg-white' : `${menuControls} top-[8%]`}`}
        ></span>
        <span
          className={`h-[1.6px] ${menuBar} ${active ? 'top-[48%] -rotate-45 bg-white' : `${menuControls} top-[92%]`}`}
        ></span>
      </div>
    </div>
  );
};
