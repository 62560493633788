import React from 'react';
import Image from '~/components/elements/Image';
import yellowShape from '~/assets/images/yellow-shape.svg';
import { motion } from 'framer-motion';

export const CaseStudyMasthead = (props) => {
  const { heading, subheading, description, previewImage, logo, name, role, company} = props
  return (
    <section className="section-contained section-top section-bot">
      <div className="container">
        <div className='flex flex-col md:flex-row gap-8 lg:items-center mt-20'>
          <div className="md:max-w-[563px] md:w-1/2 lg:w-auto">
            <h5 className="text-coral font-body t-18-tag mb-4 md:mb-7">{ heading }</h5>
            <h1 className="t-70 mb-7">{ subheading }</h1>
            <p className="t-20">{`${name ? `With ${name},`:``} ${role ? `${role}` : ''} ${company ? `at ${company}`:``}`}</p>
          </div>
          <div className="flex-auto flex">
            <div className="mx-auto relative flex items-end">
              <div className='mb-4 mr-4 lg:mr-10 lg:mb-5 relative overflow-hidden rounded-3xl'>
                <img className="lg:min-w-[340px]" src={yellowShape} alt=""/>
                <motion.div className="absolute inset-x-4 inset-y-0"
                            initial={{x: -100}}
                            whileInView={{x: 0, transition: { duration: 0.5}}}>
                  <Image className="w-full h-full" objectPosition="bottom" image={previewImage}/>
                </motion.div>
              </div>
              {logo &&
                <motion.div
                  transition={{ delay: .5 }}
                  initial={{opacity: 0, scale: 0}}
                  whileInView={{opacity: 1, scale: 1}}
                  className="absolute right-0 bottom-0 bg-white rounded-[20px] shadow-company-card p-4 lg:py-6 lg:px-9">
                  <Image className="w-24" image={logo}/>
                </motion.div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


